import React, { useState } from 'react';
import NavBar from './components/NavBar';
import SideDrawer from './components/side-drawer/SideDrawer';
import Welcome from './components/Welcome';
import Project from './components/Project';
import About from './components/About';
import History from './components/History';
import Contact from './components/Contact';
import BackDrop from './components/backdrops/BackDrop';
import FooterMessage from './components/Footer';
import './App.scss';
import './scss/Base.scss';

const App = (): JSX.Element => {
  const [sideDrawerOpen, showDrawer] = useState(false);

  return (
    <div className="portfolio" >
      <NavBar openDrawer={showDrawer} />
      <SideDrawer showDrawer={sideDrawerOpen} openDrawer={showDrawer} />
      {sideDrawerOpen && (
        <BackDrop openDrawer={showDrawer} />
      )}
      <div className="body">
        <Welcome />
        <Project />
        <About />
        <History />
        <Contact />
      </div>
      <footer>
        <FooterMessage />
      </footer>
    </div>
  );
};

export default App;
