import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import resume from '../resume/woonghui_won_resume.pdf';
import '../scss/Footer.scss';
import packageJSON from '../../package.json';

const linkedinIcon = (
  <FontAwesomeIcon className="footer-logo-links footer-linkedIn" icon={faLinkedin} />
);
const githubIcon = (
  <FontAwesomeIcon className="footer-logo-links footer-github" icon={faGithub} />
);
const resumeIcon = (
  <FontAwesomeIcon className="footer-logo-links footer-resume" icon={faFilePdf} />
);

const Footer = (): JSX.Element => {
  return (
    <div className="footer-content">
      <div>Designed and Created by Woong Hui Won. V.{packageJSON.version}</div>
      <div>
        <a
          href={'https://www.linkedin.com/in/woonghui-won/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkedinIcon}
        </a>
        <a
          href={'https://github.com/whwon'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {githubIcon}
        </a>
          <a href={resume} download className="footer-tooltip">
            {resumeIcon}
            <span className="footer-tooltipText">Click to Download Resume</span>
          </a>
      </div>
    </div>
  )
}

export default Footer;