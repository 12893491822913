import React, { useState } from 'react';
import useOnScreen from './ReferenceObserver';
import '../scss/About.scss';

const About = (): JSX.Element => {
  const [setRef, visible] = useOnScreen({ threshold: 0.3 });

  const initialState = {
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
  };
  let [{ panel1, panel2, panel3, panel4 }, accordionOpen] = useState(
    initialState
  );

  const accordion = (panel: string) => {
    if (panel === 'panel1') {
      panel1 = !panel1;
      panel2 = false;
      panel3 = false;
      panel4 = false;
      accordionOpen({ panel1, panel2, panel3, panel4 });
    } else if (panel === 'panel2') {
      panel1 = false;
      panel2 = !panel2;
      panel3 = false;
      panel4 = false;
      accordionOpen({ panel1, panel2, panel3, panel4 });
    } else if (panel === 'panel3') {
      panel1 = false;
      panel2 = false;
      panel3 = !panel3;
      panel4 = false;
      accordionOpen({ panel1, panel2, panel3, panel4 });
    } else {
      panel1 = false;
      panel2 = false;
      panel3 = false;
      panel4 = !panel4;
      accordionOpen({ panel1, panel2, panel3, panel4 });
    }
  };

  return (
    <section
      id="about"
      className={visible ? 'about ease' : 'about'}
      ref={setRef}
    >
      <h3 className={visible ? 'about-title slide-title-left' : 'about-title'}>
        ABOUT ME
      </h3>
      <div className="about-me">
        <div className="about-content">
          <p className="me">
            Hi, I am a Software Developer with experiences ranging from stabilizing and maintaining outdated infrastructures to building modern software. I have mainly worked for companies that have been around for a long time but due to their root infrastructures, they struggle to scale up. I have helped these companies by providing direct support for the users, fixing bugs reported by the user, and writing documentation. Working in smaller companies with small development teams has taught me to work with limited resources and time and molded my experience to learn on the fly.
          </p>
          <p className="about-tech">Here are a few of technologies I work with day to day:</p>
          <ul className="tech-list">
            <li>HTML/CSS/Scss</li>
            <li>Javascript (ES5+)</li>
            <li>React</li>
            <li>NodeJS</li>
            <li>MSSQL</li>
            <li>MongoDB</li>
          </ul>
          <div className="options">
            <div className="accordion-set" onClick={() => accordion('panel1')}>
              <div className={`accordion ${panel1 ? 'expanded' : ''}`}>
                <div>What I Enjoy?</div>
              </div>
              {panel1 && (
                <div className="panel">
                  <p className="description">
                    What I enjoy about web development is the beauty of seeing
                    your work come into fruition. Taking an idea, and making that
                    idea come true is what makes coding and web development so fun
                    and exciting!
                  </p>
                </div>
              )}
            </div>

            <div className="accordion-set" onClick={() => accordion('panel2')}>
              <div className={`accordion ${panel2 ? 'expanded' : ''}`}>
                <div>What are some challenges?</div>
              </div>
              {panel2 && (
                <div className="panel">
                  <p className="description">
                    What I find challenging about the Development field is that
                    technology is always changing (for better or worse), and It
                    can be very difficult to keep yourself always on top of the
                    trends.
                  </p>
                </div>
              )}
            </div>

            <div className="accordion-set" onClick={() => accordion('panel3')}>
              <div className={`accordion ${panel3 ? 'expanded' : ''}`}>
                <div>What are my preferences?</div>
              </div>
              {panel3 && (
                <div className="panel">
                  <p className="description">
                    My personal preference with development, is using React
                    Framework and SCSS for the FrontEnd, NodeJS for the backend,
                    and SQL for the Database. It is where I have the most
                    experience developing in and feel the most comfortable in.
                  </p>
                </div>
              )}
            </div>

            <div className="accordion-set" onClick={() => accordion('panel4')}>
              <div className={`accordion ${panel4 ? 'expanded' : ''}`}>
                <div>My Goal?</div>
              </div>
              {panel4 && (
                <div className="panel">
                  <p className="description">
                    My current goal for development is to learn more about UI/UX
                    designs and learn how to develop my own images and animation.
                  </p>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
