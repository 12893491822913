import React from 'react';
import '../scss/Welcome.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import resume from '../resume/woonghui_won_resume.pdf';

const linkedinIcon = (
  <FontAwesomeIcon className="logo-link linkedIn" icon={faLinkedin} />
);
const githubIcon = (
  <FontAwesomeIcon className="logo-link github" icon={faGithub} />
);
const resumeIcon = (
  <FontAwesomeIcon className="logo-link resume" icon={faFilePdf} />
);

const Welcome = (): JSX.Element => {
  return (
    <section id="welcome" className="welcome">
      <div className="welcome-content">
        <h1 className="welcome-hello">Hi, my name is</h1>
        <h2 className="welcome-title">Woong Hui Won</h2>
        <p className="welcome-message">
          I'm a Software Developer
          with a focus on Javascript and its modern frameworks/libraries.
          I have experiences working the Full Stack and do not shy away from opportunities to learn
          new languages and technologies when presented.
        </p>
        <div className="logos">
          <a
            href={'https://www.linkedin.com/in/woonghui-won/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkedinIcon}
          </a>
          <a
            href={'https://github.com/whwon'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {githubIcon}
          </a>
          <a href={resume} download className="welcome-tooltip">
            {resumeIcon}
            <span className="welcome-tooltipText">Click to Download Resume</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
