import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/Card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

const githubIcon = <FontAwesomeIcon className="github" icon={faGithub} />;

const projectCard = ({ property }: {property: any}): JSX.Element => {
  const {
    index,
    picture,
    description,
    html,
    css,
    scss,
    javascript,
    typescript,
    react,
    backend,
    database,
    netlify,
    githublink,
  } = property;

  return (
    <div id={`card-${index}`} className="card">
      <img src={picture} alt={`Project-${index}`} className="project-image" />
      <div className="card-inner">
          <a
            className="githubLink"
            href={githublink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {githubIcon}
          </a>
        <div className="details">
          <p className="project-description">{description}</p>
          <div className="project-tools">
            {html}
            {css}
            {scss}
            {javascript}
            {typescript}
            {react}
            {backend}
            {database}
            {netlify}
          </div>
        </div>
      </div>
    </div>
  );
};

projectCard.propTypes = {
  property: PropTypes.object.isRequired,
};

export default projectCard;
