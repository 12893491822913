import React from 'react';
import pokedexGame from '../../images/pokedex-game.png';
import portfolio from '../../images/tree-logo.png';
import pigGame from '../../images/pig-game.png';
import loginScreen from '../../images/login-screen.png';
import vendingMachine from '../../images/vending-machine.png';
import tsLogo from '../../images/typescriptlang-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  faJs,
  faHtml5,
  faCss3,
  faSass,
  faReact,
} from '@fortawesome/free-brands-svg-icons';

const htmlIcon = <FontAwesomeIcon className="logo-link html5" icon={faHtml5} />
const cssIcon = <FontAwesomeIcon className="logo-link css3" icon={faCss3} />;
const sassIcon = <FontAwesomeIcon className="logo-link sass" icon={faSass} />;
const jsIcon = <FontAwesomeIcon className="logo-link javascript" icon={faJs} />;
const reactIcon = (
  <FontAwesomeIcon className="logo-link react" icon={faReact} />
);
const typescriptIcon = <img className="logo-link typescript" src={tsLogo} alt="React Logo" />
// const backendIcon = <FontAwesomeIcon icon={faCode} />;
// const databaseIcon = <FontAwesomeIcon icon={faDatabase} />;
// const webserviceIcon = (
//   <FontAwesomeIcon className="logo-link react" icon={faGlobe} />
// );

const projectData = {
  properties: [
    {
      _id: 1,
      index: 0,
      picture: pokedexGame,
      description:
        'The pokedex-game started out as a simple practice for using and handling api calls, but then quickly turned into an idea to make it into a game that would be enjoyable to play. The game has 3 modes that are being worked on, the first is an actual pokedex made purely of CSS. It helps the player to learn about a Pokemon. Second game mode is to try and guess what Pokemon it is based off of just the stats to test player knowledge. And the last mode is to guess a pokemon based on the image, a throwback to the original "Whos that pokemon" played back in the original anime.',
      frontend: 'HTML, CSS, JAVASCRIPT',
      html: htmlIcon,
      css: cssIcon,
      javascript: jsIcon,
      typescript: null,
      framework: null,
      backend: null,
      database: null,
      webservice: null,
      githublink: 'https://github.com/whwon/pokedex-game',
    },
    {
      _id: 2,
      index: 1,
      picture: portfolio,
      description:
        'This is the code for the portfolio which this page is currently built on. I wanted to build a portfolio to showcase some of my abilities as a developer and share with people what I can do. Of course I still have a lot to go to be a recognized developer but I wanted to build the portfolio in more of a modern technology, using frameworks like React, using SCSS instead of CSS, and even using Netlify to showcase the usage of modern webhosting service.',
      html: htmlIcon,
      css: cssIcon,
      scss: sassIcon,
      javascript: jsIcon,
      typescript: typescriptIcon,
      framework: 'React',
      react: reactIcon,
      backend: null,
      database: null,
      webservice: 'Netlify',
      netlify: null,
      githublink: 'https://github.com/whwon/portfolio',
    },
    {
      _id: 3,
      index: 2,
      picture: pigGame,
      description:
        'The Pig-Game was built to practice more of the older fashion vanilla javascript and classic HTML and CSS. This was to help me better understand how javascript impacts the DOM and how to properly manipulate it. The reason for using more ES5 style here is because as much as it is important to know the current trend and development of ES6+, I found that in order to have a better understanding of them I needed to practice building using its predecessor. Additionally, many of the websites today are built using ES5 so it is important to be able to read, write, and understand them.',
      html: htmlIcon,
      css: cssIcon,
      javascript: jsIcon,
      typescript: null,
      framework: null,
      backend: null,
      database: null,
      webservice: null,
      githublink: 'https://github.com/whwon/pig-game',
    },
    {
      _id: 4,
      index: 3,
      picture: loginScreen,
      description:
        'This project uses nothing but HTML and CSS to build a quick and easy modern login screen that we would commonly see in companies today. It is a simple static page that is quick and responsive without the use of any Javascript to handle moving through pages.',
      frontend: 'HTML, CSS',
      html: htmlIcon,
      css: cssIcon,
      javascript: '',
      typescript: null,
      framework: null,
      backend: null,
      database: null,
      webservice: null,
      githublink: 'https://github.com/whwon/loginScreen',
    },
    {
      _id: 5,
      index: 4,
      picture: vendingMachine,
      description:
        'This was a simple test I had to perform for a interview once. A very quick usage of HTML, CSS, and Javascript page that you must be able to build in about a span of 2 hours without using any imports and libraries. This was a test to simply see how well I can think on the fly and create a basic HTML page using vanilla Javascript to respond to specific features.',
      frontend: 'HTML, CSS, JAVASCRIPT',
      html: htmlIcon,
      css: cssIcon,
      javascript: jsIcon,
      typescript: null,
      framework: null,
      backend: null,
      database: null,
      webservice: null,
      githublink: 'https://github.com/whwon/VendingMachineTest',
    },
  ],
};

export default projectData;
