import React, { useState } from 'react';
import useOnScreen from './ReferenceObserver';
import '../scss/History.scss';

const History = (): JSX.Element => {
  const [setRef, visible] = useOnScreen({ threshold: 0.3 });

  const [currentTab, selectTab] = useState(0);

  const changeTab = (index: number) => {
    selectTab(index);
  };

  return (
    <section
      id="history"
      className={visible ? 'history ease' : 'history'}
      ref={setRef}
    >
      <h3
        className={
          visible ? 'history-title slide-title-right' : 'history-title'
        }
      >
        History
      </h3>
      <p className="history-description">
        These are my previous and current work experiences as well as my
        educational background.
      </p>
      <div className="placement">{`${currentTab + 1}/6`}</div>
      <div className="records">
        <div className="workplace">

          <div
            className={`tab ${currentTab === 0 ? 'current' : ''}`}
            onClick={() => changeTab(0)}
          >
            CSM
          </div>

          <div
            className={`tab ${currentTab === 1 ? 'current' : ''}`}
            onClick={() => changeTab(1)}
          >
            Hanzo
          </div>

          <div
            className={`tab ${currentTab === 2 ? 'current' : ''}`}
            onClick={() => changeTab(2)}
          >
            CalRecycle
          </div>

          <div
            className={`tab ${currentTab === 3 ? 'current' : ''}`}
            onClick={() => changeTab(3)}
          >
            Jetstox
          </div>

          <div
            className={`tab ${currentTab === 4 ? 'current' : ''}`}
            onClick={() => changeTab(4)}
          >
            SCIF
          </div>

          <div
            className={`tab ${currentTab === 5 ? 'current' : ''}`}
            onClick={() => changeTab(5)}
          >
            SacState
          </div>

        </div>
        <div className="content">

          <div className={currentTab === 0 ? 'show' : 'hide'}>
            <div className="place">CSM Consulting Inc</div>
            <div className="position">Software Developer</div>
            <div className="date">Aug 2020 - Present</div>
            <ul>
              <li>
                Working with a small team of 4 developers, designed, architected, and built a software  utilizing MERN and Typescript.
              </li>
              <li>
                Creating migration scripts by utilizing Node/Express to move over our data from our old MSSQL to the new MongoDB.
              </li>
              <li>
                With the Adobe Flash Player ending its service by the end of 2020, was tasked in replacing the client facing software with a more modern React framework.
              </li>
            </ul>
          </div>

          <div className={currentTab === 1 ? 'show' : 'hide'}>
            <div className="place">Hattori Hanzo Shears</div>
            <div className="position">Web Developer - Full stack</div>
            <div className="date">April 2019 - Aug 2020</div>
            <ul>
              <li>
                Developing a responsive web application for mobile and desktop view, by utilizing both Media Queries and Conditional Rendering.
              </li>
              <li>
                Revisiting older back-end functions in Node and updating them to improve performance and migrate our server from NewTek to Azure.
              </li>
              <li>
                Operating in an Agile-governed workplace, using Jira/Clickup/Zendesk to consistently track and update our project progress.
              </li>
            </ul>
          </div>

          <div className={currentTab === 2 ? 'show' : 'hide'}>
            <div className="place">CalRecycle</div>
            <div className="position">Intern - IT Help Desk</div>
            <div className="date">Jan 2019 - April 2019</div>
            <ul>
              <li>
                Assist with resolving customer problems and issues in the use of CalRecycle Office Automation software, Microsoft Studio (Windows 10, Office 2016/365 includes Word, Excel, PowerPoint, Outlook, and end user database applications), and hardware.
              </li>
              <li>
                Maintenance and repair of computer hardware and peripherals for users.
              </li>
              <li>
                Configure computer systems for new users and setup computer system at user’s work area.
              </li>
            </ul>
          </div>

          <div className={currentTab === 3 ? 'show' : 'hide'}>
            <div className="place">Jetstox</div>
            <div className="position">Intern - DevOps</div>
            <div className="date">Sept 2018 - Apr 2019</div>
            <ul>
              <li>
                Working hand-in-hand with a local startup, Jetstox, to produce a stock trading application that will provide novice traders with a platform and tool to help make profitable trades.
              </li>
              <li>
                tasked in working with the DevOps team to research and develop a way to push and centralize all the backend data into the Docker Image and utilize Jenkins to automate testers for the database systems.
              </li>
              <li>
                Develop Messenger UI using React-Native and developed within their existing environment using Git.
              </li>
            </ul>
          </div>

          <div className={currentTab === 4 ? 'show' : 'hide'}>
            <div className="place">State Compensation Insurance Fund</div>
            <div className="position">Intern - NOCC</div>
            <div className="date">Sept 2017 - Aug 2018</div>
            <ul>
              <li>
                Monitoring the network environment through InterMapper software to quickly escalate any issues or degradation in service to the appropriate stakeholders.
              </li>
              <li>
                Facilitate as the center for communication by handling emails (Outlook), Avaya Phones, and documenting all cases (Word/Excel).
              </li>
              <li>
                Prevent potential data loss or equipment damage resulting from required routine and/or emergency maintenance by being the first responders to any sign of alerts.
              </li>
            </ul>
          </div>

          <div className={currentTab === 5 ? 'show' : 'hide'}>
            <div className="place">California State University, Sacramento</div>
            <div className="position">Bachelor Student</div>
            <div className="date">Aug 2014 - May 2019</div>
            <ul>
              <li>Received my Bachelor of Science in Computer Science.</li>
              <li>I graduated from SacState in Fall 2019</li>
            </ul>
          </div>

        </div>
      </div>
    </section>
  );
};

export default History;
