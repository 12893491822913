import React from 'react';
import '../scss/NavBar.scss';
import { Link, animateScroll as scroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';

const hamburgerIcon = (
  <FontAwesomeIcon className="nav-icon-hamburger" icon={faAlignJustify} />
);


const Navbar = ({ openDrawer }: { openDrawer: (arg: boolean) => void; }): JSX.Element => {

  const showDrawer = () => {
    openDrawer(true)
  }

  return (
    <header className="nav-header">
      <nav className="nav-grid">
        <div className="nav-logo">
          <img
            src={require("../images/tree-logo.png")}
            alt="Tree Logo"
            className="logo-img"
            onClick={() => scroll.scrollToTop()}
          />
        </div>
        <div className="nav-link">
          <Link
            className="nav-project"
            activeClass="active"
            to="project"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Projects
          </Link>
          <Link
            className="nav-about"
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About
          </Link>
          <Link
            className="nav-history"
            activeClass="active"
            to="history"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            History
          </Link>
          <Link
            className="nav-contact"
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contact
          </Link>
          <div onClick={showDrawer}>
            {hamburgerIcon}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
