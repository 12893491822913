import React from 'react';
import '../../scss/BackDrop.scss';

const BackDrop = ({ openDrawer }: { openDrawer: (arg: boolean) => void}): JSX.Element => {

  const closeBackDrop = () => {
    openDrawer(false)
  }

  return(
    <div className="backdrop" onClick={closeBackDrop}/>
  );
};

export default BackDrop