import React, { useState } from 'react';
import '../scss/Project.scss';
import data from './projects/Project-Data';
import Card from './projects/Project-Card';
import useOnScreen from './ReferenceObserver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const backArrow = <FontAwesomeIcon className="back-arrow" icon={faAngleLeft} />;

const nextArrow = <FontAwesomeIcon className="next-arrow" icon={faAngleRight} />;

const Project = (): JSX.Element => {
  const properties = data.properties;
  const [property, setProperty] = useState(data.properties[0]);
  const [setRef, visible] = useOnScreen({ threshold: 0.3 });

  const nextProperty = () => {
    let newIndex = property.index + 1;
    if (newIndex >= properties.length) {
      newIndex = 0;
    }
    setProperty(data.properties[newIndex]);
  };

  const prevProperty = () => {
    let newIndex = property.index - 1;
    if (newIndex < 0) {
      newIndex = properties.length - 1;
    }
    setProperty(data.properties[newIndex]);
  };

  return (
    <section
      id="project"
      className={visible ? 'project ease' : 'project'}
      ref={setRef}
    >
      <h3
        className={
          visible ? 'project-title slide-title-right' : 'project-title'
        }
      >
        projects
      </h3>
      <div className="slideshow">
        <div className="direction">
          <div onClick={prevProperty}>{backArrow}</div>
          <div className="project-current-slide">
            {property.index + 1}/{properties.length}
          </div>
          <div onClick={nextProperty}>{nextArrow}</div>
        </div>
        <Card key={property._id} property={property} />
      </div>
    </section>
  );
};

export default Project;
