import React, { useReducer } from 'react';
import useOnScreen from './ReferenceObserver';
import '../scss/Contact.scss';

const Contact = (): JSX.Element => {
  const [setRef, visible] = useOnScreen({ threshold: 0.3 });
  const [userInput, setUserInput] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      message: '',
    }
  );

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleSubmit = async () => {
    try {
      let response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...userInput }),
      });

      if (response.status >= 400 && response.status < 600) {
        throw new Error('Bad response from server');
      }

      return;

    } catch (e: any) {
      console.log(e.message);
      throw new Error(
        `There seems to have been a problem with the submission. Please email whwon19@gmail.com. I apologize for the inconvenience. Thank you!`
      );
    }
  };

  return (
    <section
      id="contact"
      className={visible ? 'contact ease' : 'contact'}
      ref={setRef}
    >
      <h3
        className={visible ? 'contact-title slide-title-left' : 'contact-title'}
      >
        CONTACT ME
      </h3>
      <p className="message">
        I would love to chat with you about new opportunities. Please feel free to
        leave me your name, email, and some messages. Would love to chat with
        you. I look forward to hearing from you soon!
      </p>

      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="contact-form"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <input
          type="text"
          name="name"
          value={userInput.name}
          onChange={handleChange}
          placeholder="FULL NAME..."
          required
        />
        <input
          type="email"
          name="email"
          value={userInput.email}
          onChange={handleChange}
          placeholder="EMAIL..."
          required
        />
        <textarea
          name="message"
          value={userInput.message}
          onChange={handleChange}
          placeholder="MESSAGE..."
          required
        ></textarea>
        <button className="submit" type="submit">
          SUBMIT
        </button>
      </form>
    </section>
  );
};

export default Contact;
